import axios from "axios";

export default {
    async getMyCommesseByCustomer(dipendenteId: string, customerId: string) {
        const data = new FormData();
        data.append("orderby", "projects_name");
        data.append("orderdir", "ASC");
        data.append("where[projects_customer_id]", customerId.toString());
        data.append("where[]", `projects_id IN (SELECT projects_id FROM rel_projects_dipendenti WHERE dipendenti_id = ${dipendenteId.toString()})`);

        const response = await axios.post("rest/v1/search/projects", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response.data;
    },
};
